import React from 'react'
import Helmet from 'react-helmet'
import { Container } from 'react-bootstrap'
import styled from 'styled-components'
import { navigate } from "gatsby"
import { Heading1, Heading2 } from '../components/text'
import { Separator } from 'components/separator'
import { Theme } from '../constants/variables'
import { Button } from '../components/button'
class Error404 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            height: 0,
            width: 0
        }
    }

    updateDimensions = () => {
        if (this.state.height !== window.innerHeight) {
            this.setState({ height: window.innerHeight })
        }
    }


    componentDidMount() {
        this.setState({ height: window.innerHeight })
        window.addEventListener('resize', this.updateDimensions, { passive: true })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions)
    }


    render() {
        const Main = styled.div`
            width: 100%;
            display: flex;
            align-items: center;
            background-color: ${Theme.color.primary};
        `
        const Content = styled.div`
            position: relative;
            z-index: 10
        `
        return (
            <div>
                <Helmet>
                    <title>Error 404</title>
                    <meta name="description" content="Page not found" />
                </Helmet>
                <Main style={{ height: `${this.state.height}px` }}>
                    <Container>
                        <Content>
                            <Heading1 color={Theme.color.dark}>Oops!</Heading1>
                            <Separator />
                            <Heading2>
                                This isn't the page you're looking for...
                            </Heading2>
                            <Button key='HomeButton' onClick={() => navigate('/')}>Return Home</Button>
                        </Content>
                    </Container>
                </Main>
            </div>
        )
    }
}

export default Error404

